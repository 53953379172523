import request from "@/utils/request";

// 获取文章列表
export function getList(data) {
  return request({
    url: "/articleList/selectClientAllAtPage",
    method: "get",
    params: data,
  });
}

// 通过id获取上下两篇篇博客
export function queryArticleNextPreById(id) {
  return request({
    method: "get",
    url: `/articleList/queryArticleNextPreById/${id}`,
  });
}

<template>
  <div class="inputWrap">
    <i class="iconfont icon-sousuo"></i>
      <input  type="text" @keyup.enter="search" v-model="value" :style="`height:${height};width:${width};border:1px solid ${borderColor}`">
  </div>
</template>

<script>
export default {
    name:'Input',
    props:{
      borderColor:{
        type:String,
        default:'#9E9E9E'
      },
      width:{
        type:String,
        default:'100%'
      },
      height:{
        type:String,
        default:'30px'
      }
    },
    data(){
      return{
        value:''
      }
    },
    mounted(){
    },
    methods:{
      search(){
        this.$emit('search',this.value)
      }
    }
}
</script>

<style lang="scss" scoped>
  .inputWrap{
    position: relative;
    width: 100%;
    .icon-sousuo{
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
    }
    input{
      padding: 0 15px;
      box-sizing: border-box;
      border-radius: 5px;
    }
  }
</style>


<template>
  <div>
    <!-- 遮罩层开始 -->
    <div class="maskWrap" v-if="isShow" @click="close"></div>
    <!-- 遮罩层结束 -->

    <!-- 弹框开始 -->
    <div class="dialogWrap" v-if="isShow">
      <div class="popUp" :style="`width:${width}`">
        <div class="item">
          <!-- 头部开始 -->
          <header>
            <div>
              <span class="title">{{ title }}</span>
            </div>

            <!-- 关闭图标开始 -->
            <div class="close">
              <i class="iconfont icon-guanbi" @click="close"></i>
            </div>
            <!-- 关闭图标结束 -->
          </header>
          <!-- 头部结束 -->

          <!-- 主体部分开始 -->
          <main style="height:${height};">
            <slot></slot>
          </main>
          <!-- 主体部分结束 -->
        </div>
      </div>
    </div>
    <!-- 弹框结束 -->
  </div>
</template>

<script>
export default {
  name: "Dialog",
  props: {
    // dialog 的宽
    width: {
      type: String,
      default: "100%",
    },
    // dialog 的高
    height: {
      type: String,
      default: "100px",
    },

    title: {
      type: String,
      default: "标题",
    },
  },
  data() {
    return {
      // 弹框显示
      isShow: this.show,
      // 监听键盘次数
      keyIndex: 0,
    };
  },
  activated() {
    // 捕获键盘事件
    this.showModel();
  },
  created() {
    this.showModel();
  },
  methods: {
    // 捕获键盘事件显示弹框
    showModel() {
      this.$nextTick(function() {
        let _this = this;
        window.onkeyup = function(event) {
          // 判断是否按下Enter，13键码为Enter
          switch (event.keyCode) {
            case 13: // Enter
              _this.keyIndex = _this.keyIndex + 1;
              if (_this.keyIndex >= 2) {
                _this.isShow = true;
              }
              break;
            case 39: // ->键
              if (_this.isShow == true) return false;
              _this.$emit("nextPageEmit");
              break;
            case 37: // <-键
              if (_this.isShow == true) return false;
              _this.$emit("prePageEmit");
              break;
            case 38: // 上键
              _this.keyIndex = _this.keyIndex + 1;
              if (_this.keyIndex >= 2) {
                document.body.scrollTop = document.documentElement.scrollTop = 0;
                _this.keyIndex = 0;
              }
              break;
            case 27: // Esc
              _this.keyIndex = 0;
              if (
                _this.isShow == false &&
                _this.$route.path == "/articleDetail"
              ) {
                history.back();
              }
              _this.isShow = false;
              break;
          }
          setTimeout(() => {
            _this.keyIndex = 0;
          }, 500);
        };
      });
    },
    //   弹框关闭
    close() {
      this.isShow = false;
      this.keyIndex = 0;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/css/base.scss";

// 遮罩层
.maskWrap {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
}

.dialogWrap {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  min-width: 960px;
  max-width: 960px;
  padding: 60px 0;
  box-sizing: border-box;

  .popUp {
    overflow: auto;

    .item {
      background-color: #fff;
      box-shadow: $boxShadow;

      header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 15px 15px;
        box-sizing: border-box;

        .close {
          cursor: pointer;
        }

        .title {
          font-weight: bold;
          font-size: 24px;
        }
      }

      main {
        padding: 15px;
      }
    }
  }
}
</style>

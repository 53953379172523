import axios from "axios";

import { getToken, setToken, removeToken } from "@/utils/cookies";

// 设置默认请求头
axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";

// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 10000,
});

// 请求拦截器
service.interceptors.request.use((config) => {
  const isToken = (config.headers || {}).isToken === false;
  if (getToken() && !isToken) {
    config.headers["Authorization"] = "Bearer " + getToken(); // 让每个请求携带自定义token
    setToken(getToken()); // 刷新token
  }
  return config;
});

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    if (res.data.code == 401) {
      removeToken();
      return false;
    }
    return res.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default service;

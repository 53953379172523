// 路由
import router from "./router";
// token
import {getToken} from "@/utils/cookies"
import store  from "./store"



router.beforeEach((to,from,next)=>{
    if(!getToken()){ // 没有token
        store.dispatch('Login').then(()=>{ // 执行登录
            // 获取站点信息
            store.dispatch('GetWebsite').then(()=>{
            })
          next();
        })
    }else{
        // 获取站点信息
        if(!store.getters.website){
            store.dispatch('GetWebsite').then(()=>{
            })
        }
        
        next();
    }
  
})
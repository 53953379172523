<template>
  <div>
    <div class="internet-content">
      <div class="item">
        <span>
          <a href="/"> 个人博客 |&nbsp;</a>
          <a href="https://beian.miit.gov.cn/" target="_blank"
            >赣ICP备2022003361号-1</a
          >
          <a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=36073202360809"
            class="police"
          >
            <img src="../../assets/image/police.png" />
            赣公网安备36073202360809号
          </a>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.internet-content {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 30px 0;

  .item {
    color: #606266;
    span {
      display: flex;
      .police {
        display: flex;
        align-items: center;
      }
    }

    a {
      display: inline;
    }
  }
}
</style>

import {getWebsite} from "@/api/blog/website/index"


const website = {
    state:{
        website:null
    },

    mutations:{
        // 设置站点信息
        SET_WEBSITE(state,website){
            state.website = website
        }
    },
    actions:{
        GetWebsite({commit}){
            return new Promise((resolve,reject) => {
                getWebsite().then(res=>{

                    res.data.websiteNav = JSON.parse(res.data.websiteNav)

                    commit("SET_WEBSITE",res.data)
                    resolve();
                }).catch(error=>{
                    reject(error)
                })
            })
        }
    }
}


export default website
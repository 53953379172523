<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" rel="stylesheet/scss">
@import "./assets/css/base.css";
</style>

<template>
  <div>
    <div class="container">
      <div>
        <Header></Header>
        <keep-alive :include="['Article', 'link']">
          <router-view class="main"></router-view>
        </keep-alive>
      </div>
    </div>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Header from "@/components/header/index";
import Footer from "@/components/footer/index";
export default {
  components: {
    Header,
    Footer,
  },
  created() {},
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.container {
  margin: auto;
  min-width: 960px;
  max-width: 960px;
  background-color: #fff;
  margin: 60px auto;
  padding: 15px 15px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 0px rgba(182, 181, 183, 0.35);
}

.footer {
  margin: auto;
  min-width: 960px;
  max-width: 960px;
}
</style>

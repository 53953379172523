<template>
  <div class="link-container">
    <!-- 下拉加载开始 -->
    <pull-up-list v-show="linKList.length > 0" @nextPage="nextPage" :loading="loading" :finished="finished">
      <!-- 链接开始 -->
      <div class="m8" v-for="(item, index) in linKList" :key="index">
        <!-- 标题开始 -->
        <div class="title-wrap mb10">
          <h3>
            <i class="iconfont icon-fenlei-copy"></i>
            <span class="title ml5">{{ item.classifyName }}</span>
          </h3>
        </div>
        <!-- 标题结束 -->

        <!-- 链接框开始 -->
        <div class="link-wrap">
          <div class="link-item m8" @click="selectWeb(children.href)" v-for="(children, cIndex) in item.blogLink"
            :key="cIndex">
            <img :src="children.faviconIco" />
            <span>{{ children.title }}</span>
          </div>
        </div>
        <!-- 链接框结束 -->
      </div>
      <!-- 链接结束 -->
    </pull-up-list>
    <!-- 下拉加载结束 -->

    <!-- 分类弹框开始 -->
    <!-- 弹框开始 -->
    <Dialog width="600px" height="100px" title="搜索">
      <div>
        <!-- 分类开始 -->
        <div class="dialog-content">
          <Tag @click.native="selectTag(item, index)" :class="['tag', { selectBc: selectClassifyFlag == index }]"
            width="100px" :icon="`icon-weibiaoti22`" :value="item.classifyName" v-for="(item, index) in classify"
            :key="index"></Tag>
        </div>
        <!-- 分类结束 -->
      </div>
    </Dialog>
    <!-- 弹框结束 -->
    <!-- 分类弹框结束 -->
  </div>
</template>

<script>
import { getLinkList } from "@/api/blog/link/index";
import { getClassifyList } from "@/api/blog/article/classify";
import pullUpList from "@/components/pullUpList";
import Dialog from "@/components/dialog/index";
import Tag from "@/components/tag/index";
export default {
  name:'link',
  components: {
    pullUpList,
    Dialog,
    Tag,
  },
  data() {
    return {
      // 链接列表
      linKList: [],
      queryParams: {
        currentPage: 1,
        size: 5,
        classifyId: null
      },
      total: 0,

      // 下一页加载条
      loading: false,
      // 是否已经全部加载完成
      finished: false,
      // 选择分类
      selectClassifyFlag: -1,
      lastClassifyFlag: -1,
      classify: [],
    };
  },
  created() {
    // 获取网站列表
    this.getLinkListFun();
    // 获取分类列表
    this.getClassifyListFun();
  },
  methods: {
    // 选中要搜索的内容
    selectTag(item, index) {

      // 操作之前先把上一次选中置为未选中
      if (this.lastClassifyFlag >= 0 && this.lastClassifyFlag != index) {
        if(this.classify[this.lastClassifyFlag].is){  
          this.classify[this.lastClassifyFlag].is = !this.classify[this.lastClassifyFlag].is
        }
      }

      item.is = !item.is;
      // 判断是否选中
      if (item.is) {
        this.selectClassifyFlag = index;
        this.queryParams.classifyId = item.classifyId;
      } else {
        this.selectClassifyFlag = -1;
        this.queryParams.classifyId = null;
      }
      this.queryParams.currentPage = 1
      this.linKList = []
      this.getLinkListFun();
      this.lastClassifyFlag = index
    },

    // 获取分类列表
    async getClassifyListFun() {
      const { data } = await getClassifyList({ type: 2 });
      this.classify = JSON.parse(JSON.stringify(data));
      this.classify = this.classify.map(item => {
        return { ...item, ...{ is: false } }
      })
    },

    // 下拉获取下一页
    nextPage() {
      this.loading = true;
      this.queryParams.currentPage++;
      this.getLinkListFun();
    },

    // 跳转对应的网站
    selectWeb(url) {
      window.open(url);
    },

    // 获取网站列表
    async getLinkListFun() {
      const { data } = await getLinkList(this.queryParams);
      this.linKList = [
        ...this.linKList,
        ...JSON.parse(JSON.stringify(data.list)),
      ];
      this.total = data.total;

      // 判断是否需要继续下拉加载
      if (this.linKList.length == this.total) {
        this.finished = true;
      } else {
        this.finished = false
      }
      // 隐藏加载进度条
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/base.scss";

// 链接框开始
.link-wrap {
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;

  .link-item {
    display: flex;
    align-items: center;
    width: 23%;
    padding: 25px;
    border: 1px solid rgba(182, 181, 183, 0.35);
    box-shadow: $boxShadow;
    border-radius: 4px;
    box-sizing: border-box;

    img {
      width: 20px;
      height: 20px;
    }

    span {
      margin-left: 8px;
    }
  }
}

// 分类弹框
.dialog-content {
  display: flex;
  align-items: center;
  justify-content: space-around;
  ;
  flex-wrap: wrap;
  margin: 15px 0;

  .a_label {
    width: 60px;
  }

  .tag {
    // margin: 5px 15px;
    margin: 5px 0;
    margin-right: 15px;
  }
}

.selectBc {
  background-color: #999;
  color: #fff;
  border-radius: 5px;
}
</style>
import Cookies from 'js-cookie'

const TokenKey = 'blog-token'


// 获取token
export function getToken(){
    return Cookies.get(TokenKey)
}

// 设置token
export function setToken(token){
    return Cookies.set(TokenKey,token,{expires:(1/24/60)*15})
}


// 清除token
export function removeToken(){
    return Cookies.remove(TokenKey)
}
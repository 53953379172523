import Vue from "vue";
import App from "./App.vue";

// 权限控制
import "./permission";

// f12
// import './utils/f12'

import "@/assets/iconfont/iconfont.css";
import "@/assets/css/common.css";
import "@/assets/css/markdown/index.scss";

import store from "./store";

// 引入路由
import router from "./router/index";

// 设置为 false 以阻止 vue 在启动时生成生产提示。
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

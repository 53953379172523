<template>
  <div>
    <div class="articleDeContainer">
      <!-- 标题开始 -->
      <div class="children-wrap">
        <h3>
          <i class="iconfont icon-weibiaoti22"></i>
          <span>{{ blogContent.title }}</span>
        </h3>
      </div>
      <!-- 标题结束 -->

      <!-- 状态栏开始 -->
      <div class="status-warp">
        <div class="item">
          <i class="iconfont icon-icon"></i>
          <span>{{ blogContent.updateTime | filterTime }}</span>
        </div>
      </div>
      <!-- 状态栏结束 -->

      <!-- 文章开始 -->
      <main>
        <article class="markdown-body" v-html="blogContent.blogSound"></article>
      </main>
      <!-- 文章结束 -->

      <div class="line"></div>

      <!-- 底部导航栏 -->
      <section>
        <!-- 上下两篇导航开始 -->
        <div class="footer-nav">
          <div
            class="left"
            v-if="preContent"
            @click="pageHandle(preContent.articleId)"
          >
            <i class="iconfont icon-shangyipian"></i>
            <span>{{ preContent.title }}</span>
          </div>
          <div
            class="right"
            v-if="nextContent"
            @click="pageHandle(nextContent.articleId)"
          >
            <span>{{ nextContent.title }}</span>
            <i class="iconfont icon-shangyipian"></i>
          </div>
        </div>
        <!-- 上下两篇导航开始 -->
      </section>
      <!-- 底部导航栏 -->
    </div>

    <!-- 弹框dialog -->
    <Dialog width="600px" height="100px" title="目录">
      <div class="catalogue">
        <ul>
          <li
            v-for="(item, index) in linkList"
            :key="index"
            @click="goUrl(item.id)"
          >
            <span><i></i> {{ item.name }}</span>
          </li>
        </ul>
      </div>
    </Dialog>
    <!-- 弹框dialog -->
  </div>
</template>

<script>
import "github-markdown-css";
import { queryArticleNextPreById } from "@/api/blog/article/articleList";
import Dialog from "@/components/dialog/index";
export default {
  components: {
    Dialog,
  },
  data() {
    return {
      // 文章
      blogContent: {
        blogSound: null,
        title: null,
        updateTime: null,
      },
      nextContent: {},
      preContent: {},
      // 获取锚点数据
      linkList: [],
      // 监听键盘次数
      keyIndex: 0,
      // 是否显示目录
      show: false,
      // 博客的id
      id: this.$route.query.id,
    };
  },

  created() {
    this.getArticleDetail();
  },
  filters: {
    filterTime(value) {
      if (!value) return value;
      return value.slice(0, 10);
    },
  },
  mounted() {
    // 获取页面中的a标签
    setTimeout(() => {
      this.listA();
    }, 500);
  },
  methods: {
    // 博客的翻页
    pageHandle(id) {
      this.id = id;
      this.getArticleDetail();
    },

    // 锚点的点击事件
    goUrl(id) {
      document.querySelector("#" + id).scrollIntoView(true);
    },
    // 获取页面中的a标签
    listA() {
      this.$nextTick(() => {
        let aData = document.getElementsByTagName("a");

        // 循环遍历 并且过滤掉a便签id为空的数据
        let link = [];
        aData.forEach((item) => {
          if (item.id !== "") {
            link.push({
              name: item.nextSibling.nodeValue,
              id: item.id,
            });
          }
        });
        this.linkList = link;
      });
    },

    // 获取文章
    async getArticleDetail() {
      const { data } = await queryArticleNextPreById(this.id);
      // 当前文章
      this.blogContent = data.currentArticle;
      // 下一篇
      this.nextContent = data.nextArticle;
      // 上一篇
      this.preContent = data.preArticle;

      // TODO 点击返回顶部
      document.querySelector("#logo").scrollIntoView(true);
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
//
.children-wrap {
  padding-top: 1rem;

  h3 {
    display: flex;
    align-items: center;
    padding: 0.5rem 0;

    i {
      font-size: 1.5rem;
      vertical-align: sub;
    }

    span {
      cursor: pointer;
    }
  }
}

//
.status-warp {
  display: flex;
  padding: 1rem 0;

  .item {
    margin-right: 0.8rem;

    i {
      margin-right: 0.2rem;
    }
  }
}

//   文章
article {
  p {
    font-size: 0.99rem;
    line-height: 2rem;
  }
}

.footer-nav {
  padding: 2rem 0;
  color: #999;
  font-size: 0.8rem;
  font-weight: bold;

  //  下一篇
  .left {
    cursor: pointer;
    float: left;

    .icon-shangyipian {
      font-size: 0.8rem;
      padding-right: 0.5rem;
    }
  }

  //  下一篇
  .right {
    cursor: pointer;
    float: right;

    .icon-shangyipian {
      font-size: 0.8rem;
      padding-right: 0.5rem;
      transform: rotate(180deg);
      display: inline-block;
    }
  }
}

// 目录
.catalogue {
  li {
    padding: 10px;
    cursor: pointer;
  }
}
</style>

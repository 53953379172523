<template>
  <div class="header_wrap" v-if="website">
    <div class="icon" id="logo">
      <a href="/article"
        ><img
          :src="website.websiteImg"
          alt="huangshaojun"
          border="0"
      /></a>
    </div>
    <div class="nav_wrap">
      <div class="nav_title">
        <span>{{website.websiteMainTitle}}</span>
        <span>{{website.websiteSubTitle}}</span>
      </div>
      <div class="nav_item">
        <ul>
          <li><router-link to="/article">{{website.websiteNav[0]}}</router-link></li>
          <li><router-link to="/link">{{website.websiteNav[1]}}</router-link></li>
          <li><router-link to="/music">{{website.websiteNav[2]}}</router-link></li>
          <li><router-link to="/about">{{website.websiteNav[3]}}</router-link></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Header",
  data() {
    return {};
  },
  computed:{
    ...mapGetters({
      website:'website'
    })
  },
  mounted(){
  }
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.header_wrap {
  display: flex;
  justify-content: space-between;
  .icon {
    width: 130px;
    img {
      width: 130px;
    }
  }
  .nav_wrap {
    .nav_title {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-weight: bold;
      span:nth-child(1) {
        font-size: 3rem;
      }
      span:nth-child(2) {
        padding: 0.5rem 0;
        font-size: 1rem;
        color: #909399;
      }
    }
    .nav_item {
      padding: 0.5rem 0;
      ul {
        display: flex;
        justify-content: space-between;
        li a {
          margin: 0 0.5rem;
          padding: 0.8rem 0.5rem;
          border-top: 1px solid #606266;
          font-size: 1rem;
          color: #606266;
          font-weight: bold;
          transition: 0.8s;
        }
        a:hover {
          background-color: #606266;
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }
}
</style>

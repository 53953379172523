<template>
  <div>
    <section v-if="total > size">
      <!-- 上下两篇导航开始 -->
      <div class="footer-nav">
        <div class="left" @click="prePage" v-show="currentPage != 1">
          <i class="iconfont icon-shangyipian"></i>
          <span>上一页</span>
        </div>
        <div class="right" @click="nextPage" v-show="total > size*currentPage">
          <span>下一页</span>
          <i class="iconfont icon-shangyipian"></i>
        </div>
      </div>
      <!-- 上下两篇导航开始 -->
    </section>
  </div>
</template>

<script>
export default {
  props: ["total", "size", "currentPage"],
  data() {
    return {
    };
  },

  created() {},
  methods: {
    // 下一页
    nextPage(){
      this.$emit("nextPage")
    },
    // 上一页
    prePage(){
      this.$emit("prePage")
    }
  },
};
</script>

<style lang="scss" scoped>
.footer-nav {
  padding: 2rem 0;
  color: #999;
  font-size: 0.8rem;
  font-weight: bold;
  //  下一篇
  .left {
    cursor: pointer;
    float: left;
    .icon-shangyipian {
      font-size: 0.8rem;
      padding-right: 0.5rem;
    }
  }
  //  下一篇
  .right {
    cursor: pointer;
    float: right;
    .icon-shangyipian {
      font-size: 0.8rem;
      padding-right: 0.5rem;
      transform: rotate(180deg);
      display: inline-block;
    }
  }
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'

import login from "./modules/login"
import website from "./modules/website"
import getters from "./getters"

Vue.use(Vuex)
const store = new Vuex.Store({
    modules:{
        login,
        website
    },
    getters
})

export default store
<template>
  <div class="pullUpList-container">
    <!-- 内容部分开始 -->
    <main>
      <slot></slot>
    </main>
    <!-- 内容部分结束 -->

    <!-- 加载底部开始 -->
    <div class="loading" id="loading" v-show="loading">
      <i class="iconfont icon-jiazai"></i> <span>加载中</span>
    </div>

    <div v-show="finished" class="finished" id="finished">
      <span>====已全部加载完成====</span>
    </div>
    <!-- 加载底部结束 -->
  </div>
</template>

<script>
import { _debounce } from "@/utils/hsj";
export default {
  name: "pullUpList",
  props: {
    finished: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },

  mounted() {
      // 监听滚动条
    window.addEventListener("scroll", this.eventListenerScroll);
  },

  destroyed(){
      // 销毁监听的滚动条
      window.removeEventListener("scroll",this.eventListenerScroll)
  },
  methods: {
    eventListenerScroll: _debounce(function () {
      if (!this.finished && !this.loading) {
        // 获取 scroll 的总高度
        let heightTop =
          document.documentElement.scrollHeight || document.body.scrollHeight;
        // 获取可视区域的高度
        let windowHeight =
          document.documentElement.clientHeight || document.body.clientHeight;
        // 获取scroll 的高度
        let scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        // 计算 scroll 距离底部的距离
        let scrollBottom = heightTop - windowHeight - scrollTop;
        if (scrollBottom <= 0) {
          this.$emit("nextPage");
        }
      }
    }, 500),
  },
};
</script>

<style lang="scss" scoped>
.loading {
  display: flex;
  justify-content: center;
  width: 100%;
  animation: fadeIn 0.5s 1;
  i {
    margin-right: 8px;
    animation: rotateLoading 5s infinite linear;
    transform-origin: 50% 50%;
    border-radius: 50%;
  }
}

.finished {
  display: flex;
  justify-content: center;
  padding: 30px 0;
  width: 100%;
  color: #909399;
  animation: fadeIn 2s 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes rotateLoading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>

import { login } from "../../api/login/login";

import {setToken,getToken} from "@/utils/cookies"



const token = {
    state:{
        token:getToken()
    },

    mutations:{
        SET_TOKEN:(state,token)=>{  // 设置token
            state.token = token
        }
    },

    actions:{
        Login({commit}){
            return new Promise((resolve,reject) => {
                login().then(res=>{
                    setToken(res.data)
                    commit('SET_TOKEN',res.data)
                    resolve()
                }).catch(error=>{
                    reject(error)
                })
            })
        }
    }
}

export default token
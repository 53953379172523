import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../pages/index.vue";
import Music from "../pages/music.vue";
import About from "../pages/about.vue";
// import Album from "../pages/blog/albums/index"
import Article from "../pages/blog/article/index";
import ArticleDetail from "../pages/blog/article/detail";
import Link from "../pages/blog/link/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: Index,
    redirect: "/article",
    children: [
      {
        path: "/article",
        name: "article",
        component: Article,
      },
      {
        path: "/articleDetail",
        name: "articleDetail",
        component: ArticleDetail,
        meta: {
          title: "详情",
        },
      },
      {
        path: "/link",
        name: "link",
        component: Link,
      },
      {
        path: "/music",
        name: "Music",
        component: Music,
      },
      {
        path: "/about",
        name: "About",
        component: About,
      },
    ],
  },
];

const router = new VueRouter({
  routes: routes,
  mode: "history",
});

export default router;

<template>
  <div>
    <div class="article-wrap" v-for="(item, index) in articleList" :key="index">
      <!-- 头部标题开始 -->
      <div class="header">
        <h2>
          <span @click="selectAlbum(item.blogAlbum.albumId)">{{
            item.blogAlbum.albumName
          }}</span>
        </h2>
      </div>
      <!-- 头部标题结束 -->

      <!-- 子标题开始 -->
      <div class="children-wrap">
        <h3>
          <i class="iconfont icon-weibiaoti22"></i>
          <span @click="goArticleDetail(item.articleId)">{{ item.title }}</span>
        </h3>
      </div>
      <!-- 子标题结束 -->

      <!-- 状态栏开始 -->
      <div class="status-warp">
        <div class="item">
          <i class="iconfont icon-icon"></i>
          <span>{{ item.createTime | filterTime }}</span>
        </div>
        <div class="item">
          <i class="iconfont icon-biaoqian"></i>
          <span>{{ item.blogClassify.classifyName }}</span>
        </div>
      </div>
      <!-- 状态栏结束 -->
    </div>

    <!-- 分页开始 -->
    <pagination
      :total="total"
      :size="queryParams.size"
      :currentPage="queryParams.currentPage"
      @prePage="prePage"
      @nextPage="nextPage"
      v-show="total > 0"
    />
    <!-- 分页结束 -->

    <!-- 弹框开始 -->
    <Dialog
      width="600px"
      height="100px"
      title="搜索"
      @nextPageEmit="nextPage"
      @prePageEmit="prePage"
    >
      <div>
        <Input @search="searchKeyword"></Input>

        <!-- 专辑开始 -->
        <div class="dialog-content">
          <Tag
            @click.native="selectTag(1, item, index)"
            :class="['tag', { selectBc: selectAlbumFlag == index }]"
            :icon="`icon-weibiaoti22`"
            :value="item.albumName"
            v-for="(item, index) in album"
            :key="index"
          ></Tag>
        </div>
        <!-- 专辑结束 -->

        <!-- 分类开始 -->
        <div class="dialog-content">
          <Tag
            @click.native="selectTag(2, item, index)"
            :class="['tag', { selectBc: selectClassifyFlag == index }]"
            width="100px"
            :icon="`icon-weibiaoti22`"
            :value="item.classifyName"
            v-for="(item, index) in classify"
            :key="index"
          ></Tag>
        </div>
        <!-- 分类结束 -->
      </div>
    </Dialog>
    <!-- 弹框结束 -->
  </div>
</template>

<script>
import { getList } from "@/api/blog/article/articleList";
import { getAlbumList } from "@/api/blog/article/album";
import { getClassifyList } from "@/api/blog/article/classify";

import pagination from "@/components/pagination/index";
import Dialog from "@/components/dialog/index";
import Input from "@/components/input/index";
import Tag from "@/components/tag/index";
export default {
  name: "Article",
  components: {
    pagination,
    Dialog,
    Input,
    Tag,
  },
  data() {
    return {
      // 专辑
      album: [],
      // 分类
      classify: [],
      // 文章列表
      articleList: [],
      // 搜索参数
      queryParams: {
        currentPage: 1,
        size: 5,
        title: null,
        articleAlbumId: null,
        articleClassifyId: null,
      },
      total: 0,
      // 选中的专辑
      selectAlbumFlag: -1,
      selectClassifyFlag: -1,
      lastClassifyFlag: -1,
    };
  },
  created() {
    // 获取文章列表
    this.getListFun();

    // 获取专辑列表
    this.getAlbumListFun();

    // 获取分类列表
    this.getClassifyListFun();
  },
  filters: {
    filterTime(value) {
      return value.substr(0, 10);
    },
  },
  methods: {
    // 去文章详情页面
    goArticleDetail(id) {
      this.$router.push({ path: "/articleDetail", query: { id: id } });
    },

    // 选中专辑
    selectAlbum(id) {
      this.queryParams.articleAlbumId = id;
      this.getListFun();
    },

    // 选中要搜索的内容
    selectTag(type, item, index) {
      item.is = !item.is;
      if (type == 1) {
        // 判断是否选中
        if (item.is) {
          // 操作之前先把上一次选中置为未选中
          if (this.selectAlbumFlag >= 0 && this.selectAlbumFlag != index) {
            if (this.album[this.selectAlbumFlag].is) {
              this.album[this.selectAlbumFlag].is = !this.album[
                this.selectAlbumFlag
              ].is;
            }
          }

          this.selectAlbumFlag = index;
          this.queryParams.articleAlbumId = item.albumId;
        } else {
          this.selectAlbumFlag = -1;
          this.queryParams.articleAlbumId = null;
        }
      } else if (type == 2) {
        // 操作之前先把上一次选中置为未选中
        if (this.selectClassifyFlag >= 0 && this.selectClassifyFlag != index) {
          if (this.classify[this.selectClassifyFlag].is) {
            this.classify[this.selectClassifyFlag].is = !this.classify[
              this.selectClassifyFlag
            ].is;
          }
        }

        if (item.is) {
          this.selectClassifyFlag = index;
          this.queryParams.articleClassifyId = item.classifyId;
        } else {
          this.selectClassifyFlag = -1;
          this.queryParams.articleClassifyId = null;
        }
      }
      this.queryParams.currentPage = 1;
      this.getListFun();
    },

    // 获取文章列表
    async getListFun() {
      const { data } = await getList(this.queryParams);
      this.articleList = JSON.parse(JSON.stringify(data.list));
      this.total = JSON.parse(JSON.stringify(data.total));
    },

    // 获取专辑列表
    async getAlbumListFun() {
      const { data } = await getAlbumList();
      this.album = JSON.parse(JSON.stringify(data));

      this.album = this.regroupData(this.album);
    },

    // 获取分类列表
    async getClassifyListFun() {
      const { data } = await getClassifyList();
      this.classify = JSON.parse(JSON.stringify(data));

      this.classify = this.regroupData(this.classify);
    },

    regroupData(list) {
      return list.map((item) => {
        return {
          ...item,
          ...{ is: false },
        };
      });
    },

    // 下一页
    nextPage() {
      // 总页数 < 当前页大小 * 当前页
      if (this.total < this.queryParams.size * this.queryParams.currentPage)
        return false;
      this.queryParams.currentPage += 1;
      this.getListFun();
    },

    // 上一页
    prePage() {
      // 总页数 > 当前页大小 * 当前页
      if (this.queryParams.currentPage == 1) return false;
      this.queryParams.currentPage -= 1;
      this.getListFun();
    },

    // 输入框搜索
    searchKeyword(e) {
      this.queryParams.title = e;
      this.getListFun();
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.article-wrap {
  border-bottom: 1px solid #f3f3f3;
  padding: 1rem 0;

  h2 {
    font-size: 1.2rem;
    cursor: pointer;

    span {
      color: #000;
    }

    span::before {
      content: "#";
      color: #909399;
      margin-right: 0.5rem;
    }
  }

  .children-wrap {
    padding-top: 1rem;

    h3 {
      display: flex;
      align-items: center;
      padding: 0.5rem 0;

      i {
        font-size: 1.5rem;
        vertical-align: sub;
      }

      span {
        cursor: pointer;
      }
    }
  }

  .status-warp {
    display: flex;
    padding: 1rem 0;

    .item {
      margin-right: 0.8rem;

      i {
        margin-right: 0.2rem;
      }
    }
  }
}

.footer_nav {
  padding: 2rem 0;
  color: #999;
  font-size: 0.8rem;
  font-weight: bold;

  //  下一篇
  .left {
    cursor: pointer;
    float: left;

    .icon-shangyipian {
      font-size: 0.8rem;
      padding-right: 0.5rem;
    }
  }

  //  下一篇
  .right {
    cursor: pointer;
    float: right;

    .icon-shangyipian {
      font-size: 0.8rem;
      padding-right: 0.5rem;
      transform: rotate(180deg);
      display: inline-block;
    }
  }
}

// 专辑弹框
.dialog-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 15px 0;

  .a_label {
    width: 60px;
  }

  .tag {
    // margin: 5px 15px;
    margin: 5px 0;
    margin-right: 5px;
  }
}

.selectBc {
  background-color: #999;
  color: #fff;
  border-radius: 5px;
}
</style>

// 防抖
export function _debounce(fn, delay) {
    var time = delay || 200;
    var timer;
    return function () {
        var th = this;
        var args = arguments;
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(function () {
            timer = null;
            fn.apply(th, args);
        }, time);
    };
}


// 节流
export function _throttle(fn, interval) {
    var last;
    var timer;
    var time = interval || 200;
    return function () {
        var th = this;
        var args = arguments;
        var now = +new Date();
        if (last && now - last < interval) {
            clearTimeout(timer);
            timer = setTimeout(function () {
                last = now;
                fn.apply(th, args);
            }, time);
        } else {
            last = now;
            fn.apply(th, args);
        }
    }
}


// 时间格式化
let format = require("date-format")


/**
 * 
 * @param {时间格式} str 
 * @param {时间} date 
 * @returns {转换后的代码}
 */
export function formatDate(str,date=new Date()){
    return format.asString(str,date)
}




<template>
  <div>
      <div class="tagWrap" :style="`width:${width};height:${height}`">
          <i :class="`iconfont ${icon}` "></i>
        <span>{{value}}</span>
      </div>
  </div>
</template>

<script>
export default {
    name:'Tag',
    props:{
        value:{
            type:String,
            default:'内容'
        },
        width:{
            type:String,
            default:'fit-content'
        },
        height:{
            type:String,
            default:'30px'
        },
        icon:{
            type:String,
            default:''
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/css/base.scss';
    .tagWrap{
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #9E9E9E;
        border-radius: 5px;
        box-shadow: $boxShadow;
        padding: 0 5px;
        cursor: pointer;
    }
</style>
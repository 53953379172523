import request from "@/utils/request";

// 获取验证码
export function getCode(data) {
  return request({
    url: "/captchaImage",
    method: "get",
    data: data,
  });
}

// 登录
export function login() {
  const data = {
    username: "huangshaojun",
    password: "huangshaojun",
    code: "hsjsnbb",
  };
  return request({
    url: "/user/login",
    method: "post",
    data: data,
  });
}
